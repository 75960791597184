.custom-submit__container {
  display: flex;
  text-align: left;
  margin-top: 1.2rem;
}

.custom-submit__consent-text {
  font-size: 13px;
  font-weight: normal;
  line-height: initial;
  color: var(--soft-gray);
  font-family: 'roboto', sans-serif;
}

.custom-submit__btn {
  width: 100%;
  background-color: var(--kelly-green);
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  text-align: center;
  color: var(--white);
  font-weight: 900;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
  text-transform: uppercase;
}

.custom-submit__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  color: var(--kelly-green);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}
